import $ from 'jquery';


$(".slider")
.on('beforeChange', function(event, slick, currentSlide, nextSlide){
  
})
.on('afterChange init', function(event, slick, direction){
  $('.slider-container').removeClass('hide').addClass('fadeInUp');
  // find current slide
  for (var i = 0; i < slick.$slides.length; i++)
  {
      var $slide = $(slick.$slides[i]);
      if ($slide.hasClass('slick-current')) {
          // update DOM siblings
          $slide.prev().find('.slider-container').removeClass('fadeInUp').addClass('hide');
          $slide.next().find('.slider-container').removeClass('fadeInUp').addClass('hide');
          break;
      }
  }
}).slick({
 
    // normal options...
    infinite: false,
    centerMode: false,
    nextArrow: '<button type="button" class="slick-next"><i class="bi bi-chevron-compact-right"></i></button>',
    prevArrow: '<button type="button" class="slick-prev"><i class="bi bi-chevron-compact-left"></i></button>',
   
    
  });
